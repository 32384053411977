<template>
  <b-modal
    id="modal-contract-update-time"
    ref="refModalContractUpdateTime"
    :title="itemLocal.isExtend === true ? t('Gia hạn hợp đồng') : t('Đăng ký ngày chuyển đi')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-alert
        show
        variant="primary"
      >
        <div class="alert-body">
          {{ t('Hợp đồng hiện tại có ngày hết hạn là') }}: <strong>{{ parseDateToString(itemLocal.endDate) }}. </strong> <br>
          <span v-if="itemLocal.newEndDate && itemLocal.isExtend">{{ t('Sau khi gia hạn, hợp đồng có ngày hết hạn là') }}: <strong>{{ parseDateToString(itemLocal.newEndDate) }}</strong> </span>
          <span v-if="itemLocal.isExtend"><br>{{ t('Giá mới của căn hộ là') }}: <strong>{{ Number(itemLocal.room.price).toLocaleString() }} đ</strong>, {{ t('giá của hợp đồng cũ là') }}: <strong>{{ Number(itemLocal.oldPrice).toLocaleString() }} đ</strong> </span>

        </div>
      </b-alert>
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row v-if="itemLocal.isExtend">
          <b-col

            cols="12"
          >
            <validation-provider

              #default="validationContext"
              name="extendMonth"
              rules="required"
            >

              <select-extend-month
                v-if="itemLocal.isExtend"
                v-model="itemLocal.extendMonth"
                required
                :label="t('Số tháng gia hạn')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>

          </b-col>
          <b-col
            v-if="!isTingTong && itemLocal.extendMonth && itemLocal.extendMonth.id === 0"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="newEndDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.newEndDate"
                required
                :default-value="itemLocal.newEndDate"
                :label="t('Hạn hợp đồng mới')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              /></validation-provider>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col

            cols="12"
          >
            <select-date
              v-model="itemLocal.leavingDate"
              :default-value="itemLocal.leavingDate"
              :label="t('Ngày sẽ chuyển đi')"
            />
          </b-col>
          <b-col cols="12">
            <b-form-group :label="t('Ghi chú')">
              <b-form-textarea
                id="leavingNote"
                v-model="itemLocal.leavingNote"
                :placeholder="t('Trả cọc hay bỏ cọc, có bị phạt hay không...')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="itemLocal.isExtend">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Tiền thuê mới"
              rules="required"
            >
              <number-input
                v-model="itemLocal.price"
                required
                :disabled="isTingTong"
                :label="t('Tiền thuê mới')"
                :default-value="itemLocal.price"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Tiền cọc mới"
              rules="required"
            >
              <number-input
                v-model="itemLocal.deposit"
                required
                :label="t('Tiền cọc mới')"
                :default-value="itemLocal.deposit"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col v-if="!isTingTong">
            <validation-provider
              #default="validationContext"
              name="deposit"
              rules="required"
            >
              <select-deposit-month
                v-model="itemLocal.depositMonthObject"
                :default-value="itemLocal.depositMonthObject"
                :label="t('Tiền cọc')"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="itemLocal.isExtend && isTingTong">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="discount"
              rules="required"
            >
              <number-input
                v-model="itemLocal.promotionMonth"
                required
                :label="t('Số tháng giảm')"
                :default-value="itemLocal.promotionMonth"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Số tiền giảm mỗi tháng"
              rules="required"
            >
              <number-input
                v-model="itemLocal.promotionPricePerMonth"
                required
                :label="t('Số tiền giảm mỗi tháng')"
                :default-value="itemLocal.promotionPricePerMonth"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <!-- <b-col
            v-if="isTingTong"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Giảm trừ cọc"
              rules="required"
            >
              <number-input
                v-model="itemLocal.promotionDeposit"
                required
                :label="t('Giảm trừ cọc')"
                :default-value="itemLocal.promotionDeposit"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col> -->
        </b-row>
        <b-row v-if="itemLocal.isExtend">
          <b-col cols="12">
            <b-form-group :label="t('Ghi chú')">
              <b-form-textarea
                id="extendNote"
                v-model="itemLocal.extendNote"
                :placeholder="t('Khuyến mại, mã đề xuất, lưu ý')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import { parseDateToString } from '@/auth/utils';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDepositMonth from '@/views/components/SelectDepositMonth.vue';
import SelectExtendMonth from '@/views/components/SelectExtendMonth.vue';
import useContractUpdateTimeModal from './useContractUpdateTimeModal';

export default {
  components: {
    BModal,
    SelectDate,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BForm,
    ValidationObserver,
    ValidationProvider,
    NumberInput,
    SelectDepositMonth,
    SelectExtendMonth,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'extend',
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalContractUpdateTime,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      isTingTong,
      onSubmit,
      t,
    } = useContractUpdateTimeModal(props, emit, refFormObserver);

    return {
      refModalContractUpdateTime,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      isTingTong,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      parseDateToString,
    };
  },
};
</script>
